<template>
  <div class="modal fade " id="priceInfoModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Price Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- start amount -->
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <div class="">
                <label>Amount</label>
                <input v-model="productPrice.amount" type="number" class="form-control"
                       placeholder="Enter amount in cents"/>
              </div>
            </div>
            <!--end amount-->
            <!-- discount status -->
            <div class="form-group col-sm-12 col-md-6">
              <div class="custom-control custom-switch custom-control-inline align-items-center"
                   style="margin-top: 2rem">
                <div>
                  <input v-model="hasDiscount" type="checkbox"
                         class="custom-control-input" checked
                         id="hasDiscount">
                  <label class="custom-control-label mr-1"
                         for="hasDiscount"></label>
                </div>
                <span class="font-medium-1">{{ hasDiscount ? "Has Discount" : "No Discount" }}</span>
              </div>
            </div>
          </div>

          <!-- discount content-->
          <hr class="">
          <div v-if="hasDiscount" class="row">

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>Discount</label>
                <input v-model="productPrice.discount" type="number" class="form-control"
                       placeholder="Enter discount in cents"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <div class="custom-control custom-switch custom-control-inline align-items-center"
                     style="margin-top: 2rem">
                  <div>
                    <input v-model="discountType" type="checkbox"
                           class="custom-control-input" checked
                           id="discountType">
                    <label class="custom-control-label mr-1"
                           for="discountType"></label>
                  </div>
                  <span
                      class="font-medium-1">{{ discountType ? "Fixed Amount" : "Percentage" }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <div class="controls">
                  <label>Start Date & Time </label>
                  <div class="d-flex form-control align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="productPrice.discount_start_at" :update-on-input="true"
                                    mode="dateTime"
                                    :min-date="new Date()"
                                    is24hr: true
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <div class="controls">
                  <label>End Date & Time</label>
                  <div class="d-flex form-control align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="productPrice.discount_end_at" :update-on-input="true"
                                    mode="dateTime"
                                    :min-date="new Date()"
                                    is24hr: true
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updatePriceInformation">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from 'v-calendar';
import {mapActions} from "vuex";

export default {
  name: "PriceInfoModal",
  props: ['currentPrice'],
  emits: ['getUpdatedData'],
  mixins: [ShowToastMessage,Loader],
  data() {
    return {
      hasDiscount: 0,
      discountType: 0,
      productPrice: {
        state: '',
        condition: '',
        amount: 0.00,
        discount: 0.0,
        status: 0,
        discount_status: 0,
        discount_type: 0,
        discount_start_at: null,
        discount_end_at: null
      },
      productPriceId: null,
    }
  },
  watch: {
    currentPrice(value) {
      this.productPriceId = value.id;
      this.productPrice = {
        amount: value.amount / 100,
        discount: value.discount /100,
        status: value.status === 'Active' ? 1 : 0,
        discount_status: value.discount_status === 'Active' ? 1 : 0,
        discount_type: value.discount_type === 'Percentage' ? 0 : 1,
        discount_start_at: value.discount_start_at ?? null,
        discount_end_at: value.discount_end_at ?? null
      }
      this.hasDiscount = value.discount_status === 'Active'
      this.discountType = value.discount_type !== 'Percentage'
    },
    hasDiscount() {
      this.productPrice.discount_status = this.hasDiscount ? 1 : 0
    },
    discountType() {
      this.productPrice.discount_type = this.discountType ? 1 : 0
    }
  },
  computed: {},
  components: {
    DatePicker
  },
  methods: {
    ...mapActions({
      putProductPrice: 'appProductPrices/putProductPrice'
    }),
    async updatePriceInformation() {
      this.loader(true);
      const dataObj = {
        id: this.productPriceId,
        data: {
          ...this.productPrice,
          amount: this.productPrice.amount * 100,
          discount: this.productPrice.discount * 100,
        },
      };
      const response = await this.putProductPrice(dataObj);
      if (response.status === 200 || response.status === 201) {
         this.loader(false);

        document.querySelector('[data-dismiss="modal"]').click();
         this.showToastMessage({
          type:'success',
          message:'Price updated'
         });
        this.$emit('getUpdatedData');

        this.errors = {};
      
        return;
      } 
      this.loader(false);
       this.errors = response.errors;
        if (response.message) {
        this.showToastMessage(response);
      }
      
    
    }
  }
}
</script>

<style scoped>

</style>