<template>
  <AppLayout>
    <template v-slot:appContent>
      <div ref="singleServiceViewRef">
        <div class="content-body">
          <section class="page-user-profile">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
              <h4 class="">Products/View</h4>
              <div>
                <router-link :to="{...previousRoute}">
                  <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="d-block d-sm-flex  align-items-center">
                      <div class="mr-2 position-relative">
                        <img :src="product?.images?.[product.images.length-1] ?? ''" alt="users avatar"
                             class="users-avatar-shadow rounded-circle"
                             :style="{border:'1px solid gray'}" height="120" width="120">
                        <div
                            class="position-absolute update-image-avatar d-flex align-items-center justify-content-center"
                            @click="openImageUpdateModal" v-if="can('product-update')">
                          <i class='bx bxs-camera'></i>
                        </div>
                      </div>
                      <div class="media-body ">
                        <h4 class="media-heading">
                          <span class="bullet bullet-sm"
                          ></span>
                        </h4>

                        <p><span class="text-muted"></span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="card ">
                      <div class="card-body">
                        <div class="d-flex align-items-center mb-1">
                          <div>
                            <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                              Basic Information
                            </h5>
                          </div>
                          <div class="ml-1" style="cursor: pointer;"  v-if="can('product-update')">
                            <span data-toggle="modal" data-target="#basicInfoEditModal" title="Edit"
                                  class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                          </div>
                        </div>
                        <ul v-if="product.name !== undefined" class="list-unstyled">
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Name: </span>
                            <span>{{ product.name }}</span>
                          </li>
                          <li class="mb-1" v-if="product?.productCategory?.name">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Category: </span>
                            <span>{{ product?.productCategory?.name }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Code: </span>
                            <span>{{ product.code }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Type: </span>
                            <span>{{ product.type }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Source: </span>
                            <span class= "badge badge-pill"
                                  :class="product.source =='Default'?'badge-light-danger' : 'badge-light-info' ">{{ product.source }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Pre Order Status: </span>
                            <span>{{ product.pre_order }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Return Appointment Status: </span>
                            <span>{{ product.return_appointment_status }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Customer Portal Status: </span>
                            <span>{{ product.customer_portal_status }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Activation Status: </span>
                            <span>{{ product.status }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Description: </span>
                            <span v-if="product.description" v-html="product.description"></span>
                            <span v-else>No Description Provided </span>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card ">
                      <div class="card-body">
                        <div class="d-flex align-items-center mb-1">
                          <div>
                            <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">Meta
                              Information</h5>
                          </div>
                          <div class="ml-1" style="cursor: pointer;"  v-if="can('product-update')">
                            <span data-toggle="modal" data-target="#metaInfoEditModal" title="Edit"
                                  class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                          </div>
                        </div>
                        <ul v-if="product.name !== undefined" class="list-unstyled">
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Author: </span>
                            <span>{{ product?.meta_author ?? 'Not provided' }}</span>
                          </li>
                          <li class="mb-1" v-if="product?.productCategory?.name">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Description: </span>
                            <span>{{  product?.meta_description ?? 'Not provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Keywords: </span>
                            <span>{{  product?.meta_keywords ?? 'Not provided' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Other Meta Data: </span>
                            <span>{{  product?.other_meta_data ?? 'Not provided' }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="tab-content  ">
                      <div aria-labelledby="profile-tab">
                        <div class="card">
                          <div class="card-body">
                            <h4>Price Information</h4>
                            <div class="accordion" id="accordionOuter">
                              <div class="card" v-for="(singleState, index) in state" :key="index">
                                <div data-toggle="collapse" :data-target="`#collapse-${index}`"
                                     aria-expanded="true" :aria-controls="`collapse-${index}`"
                                     class="card-header rounded" :id="`heading-${index}`"
                                     :style="stateDaysRowStyle[index]">
                                  <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left" type="button">
                                      {{ singleState.name }}
                                    </button>
                                  </h2>
                                </div>

                                <div :id="`collapse-${index}`" class="collapse" :aria-labelledby="`heading-${index}`"
                                     data-parent="#accordionOuter">
                                  <div class="card-body">
                                    <!-- start inner condition-->
                                    <div class="accordion" id="accordionInner"
                                         v-if="product.productPrices !== undefined">
                                      <div class="card" v-for="(singleCondition, index) in productItemConditions"
                                           :key="index">
                                        <div v-for="singlePrice in product.productPrices"
                                             :key="singlePrice.id">
                                          <div class="card-header rounded p-0" :id="`headingInner-${index}`"
                                               :style="stateDaysRowStyle[index]"
                                               v-if="singleState.value === singlePrice.state && singleCondition.name.toLowerCase() === singlePrice.condition.toLowerCase() ">
                                            <h2 class="mb-0" >
                                              <button class="btn btn-link btn-block text-left" type="button"
                                                      data-toggle="collapse" :data-target="`#collapseInner-${index}`"
                                                      aria-expanded="true" :aria-controls="`collapseInner-${index}`">
                                                {{ singleCondition.name }}
                                              </button>
                                            </h2>
                                            <!-- state status & update action-->
                                            <div class="d-flex">
                                              <div
                                                  class="custom-control custom-switch custom-control-inline align-items-center justify-content-center">
                                                <div  v-if="can('product-update')">
                                                  <input v-model="singlePrice.status" type="checkbox"
                                                         class="custom-control-input" checked
                                                         :id="`stateStatus-${singlePrice.id}`"
                                                         @change="updatePriceStatus(singlePrice.status, singlePrice.id)">
                                                  <label class="custom-control-label mr-1"
                                                         :for="`stateStatus-${singlePrice.id}`"></label>
                                                </div>
                                                <span
                                                    class="font-medium-1">
                                                  {{singlePrice.status ? "Active" : "Inactive" }}
                                                </span>
                                              </div>
                                              <div  v-if="can('product-update')" v-show="singlePrice.status === true" class="cursor-pointer"
                                                   :style="{height:'18px'}"
                                                   @click="getSingleProductPrice(singlePrice.id)"
                                                   data-toggle="modal"
                                                   data-target="#priceInfoModal">
                                                <i class='bx bx-edit'></i>
                                              </div>
                                            </div>
                                          </div>

                                          <div :id="`collapseInner-${index}`" class="collapse"
                                               :aria-labelledby="`headingInner-${index}`"
                                               data-parent="#accordionInner"
                                               v-if="singleState.value === singlePrice.state && singleCondition.name.toLowerCase() === singlePrice.condition.toLowerCase() ">
                                            <div class="card-body">
                                              <div class="row">
                                                <div class="d-flex justify-content-between ">
                                                  <div class="col-sm-12 col-md-12">
                                                    <h6><small class="text-muted pb-0">Amount:</small></h6>
                                                    <p>{{ parseFloat(singlePrice.amount / 100).toFixed(2) }}</p>
                                                  </div>
                                                  <div class="col-sm-12 col-md-12">
                                                    <h6><small class="text-muted pb-0">Condition:</small></h6>
                                                    <p>{{ singlePrice.condition }}</p>
                                                  </div>
                                                  <div class="col-sm-12 col-md-12">
                                                    <h6><small class="text-muted pb-0">Type:</small></h6>
                                                    <p>{{ singlePrice.discount_type }}</p>
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <!-- end inner condition-->
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" data-toggle="modal" data-target="#productImageUpdateModal"></div>
            <ProductImageUpdateModal v-on:getSingleView="uploadImage($event)" :previousImages="product?.images ?? []"/>
            <PriceInfoModal @getUpdatedData="updatePriceInfo($event)" :current-price="this.productPrice"/>
            <BasicInfoModal @getProductOnUpdate="updateBasicOrMetaInfo"
                            :basic-info-obj="this.initialProductData"/>
            <MetaInfoUpdateModal @getProductOnUpdate="updateBasicOrMetaInfo"
                            :basic-info-obj="this.initialProductData"/>
          </section>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import BasicInfoModal from "@/views/backEnd/products/includes/BasicInfoModal";
import PriceInfoModal from "@/views/backEnd/products/includes/PriceInfoModal"
import MetaInfoUpdateModal from "@/views/backEnd/products/includes/MetaInfoUpdateModal";
import ProductImageUpdateModal from "@/views/backEnd/products/includes/ProductImageUpdateModal";
// core packages
import {mapActions, mapGetters} from "vuex";
// mixins
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "ProductView",
  components: {
    MetaInfoUpdateModal,
    ProductImageUpdateModal,
    AppLayout,
    BasicInfoModal,
    PriceInfoModal
  },
  mixins: [ShowToastMessage, Loader,Authorization],

  data() {
    return {
      initialProductData: {
        name: '',
        added_by: '',
        discount: '',
        code: '',
        standard_unit_price: '',
        description: '',
        type: '',
        pre_order: '',
        return_appointment_status: '',
        customer_portal_status: '',
        status: '',
        meta_author:'',
        meta_description:'',
        meta_keywords:'',
        other_meta_data:'',
        images:[],
        source: '',

      },
      getProductParams: {
        with_relation: ['productCategory', 'addedBy', 'productPrices', 'media'],
      },
      productID: null,
      standardUnitPriceObj: [],
      listUnitPriceObj: [],
      image: '',
      initialStandardDataObj: [],
      getSettingsParams: {
        type: ['default', 'product_item'],
        key: ['default_state', 'product_item_condition'],
      },
      stateDaysRowStyle: [
        {
          backgroundColor: '#ecf4ff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fdf4e9',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#f1fff1',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fff7f7',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#effbff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fbf7ff',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },
        {
          backgroundColor: '#fbffed',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px'
        },


      ],
      productPrices: {}
    }

  },
  computed: {
    ...mapGetters({
      product: 'appProducts/product',
      previousRoute: 'previousRoute',
      productPrice: 'appProductPrices/productPrice'
    }),
    productItemConditions() {
      return this.$store.getters['appSettings/settingProductItemCondition'].value;
    },
    state() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },
  },
  watch: {
    product() {
      this.product.productPrices.map((current) => {
        current.status = current.status === 'Active'
      });
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getProduct: 'appProducts/getProduct',
      getProductPrice: 'appProductPrices/getProductPrice',

      putProduct: 'appProducts/putProduct',
      putProductPrice: 'appProductPrices/putProductPrice',

      resetProduct: 'appProducts/resetProduct',
      putImage: 'appProducts/putImage',


    }),
    openImageUpdateModal() {
      document.querySelector('[data-target="#productImageUpdateModal"]').click();
    },
    async uploadImage(imageBase64) {

      const dataObj = {
        id: this.product.id,
        data: {
          images: [imageBase64]
        }
      }
      this.loader(true);
      await this.putImage(dataObj).then(async (response) => {
        this.loader(false);
        if (response.status === 201 || response.status === 200) {
          document.querySelector('[data-target="#productImageUpdateModal"]').click();
          await this.getSingleProduct(this.$route.params.id)
          return;
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
    async updatePriceInformation(updatedData) {
      console.log(updatedData);
      const dataObj = {
        id: updatedData.id,
        data: updatedData.data,
      };
      const response = await this.putProductPrice(dataObj);
      if (response.status === 200) {
        this.showToastMessage({type: 'success', message: 'Product Price Status Updated'});
      } else {
        this.errors = response.errors;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    },
    async updatePriceStatus(status, id) {
      const dataObj = {id: id, data: {status: status === true ? 1 : 0}}
      await this.updatePriceInformation(dataObj);
    },
    async getSingleProduct() {
      let paramObj = {
        id: this.$route.params.id,
        params: this.getProductParams
      };
      await this.getProduct(paramObj).then((response) => {
        if (response.status === 200) {
          this.productID = this.product.id;
          this.initialProductData = {
            name: this.product.name,
            code: this.product.code,
            description: this.product.description,
            product_category_id: this.product.productCategory.id,
            type: this.product.type,
            pre_order: this.product.pre_order,
            return_appointment_status: this.product.return_appointment_status,
            customer_portal_status: this.product.customer_portal_status,
            status: this.product.status,
            meta_author: this.product.meta_author,
            meta_description: this.product.meta_description,
            meta_keywords: this.product.meta_keywords,
            other_meta_data: this.product.other_meta_data,
            images: this.product.images,
            source: this.product.source,
          };
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async updateBasicOrMetaInfo(updatedData) {
      const productPramObj = {
        id: this.$route.params.id,
        data: updatedData.data,
      };
      this.loader(true);
      await this.putProduct(productPramObj).then(async (response) => {
        await this.loader(false);
        if (response.status === 200) {
          document.querySelector(`[data-target="#${updatedData.modalName}"]`).click();
          let toastObj = {message: 'product related info has Updated', type: 'success'};
          this.showToastMessage(toastObj);
        }
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
    async updatePriceInfo() {
      await this.getSingleProduct(this.$route.params.id);
      document.querySelector('[data-target="#priceInfoModal"]').click();

    },
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getSingleProductPrice(priceId) {
      //this.loader(true);
      const paramObj = {
        id: priceId,
      };
      this.getProductPrice(paramObj).then((response) => {
        if (response.status === 200) {
          this.loader(false);
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    }
  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getSingleProduct();
    await this.loader(false);
   }
}
</script>

<style scoped>
h6 {
  color: #475F7B !important;
}

ul {
  text-decoration: none;
  list-style: none;
}

p {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar > i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.single-edit-btn {
  cursor: pointer;
}
</style>